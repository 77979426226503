import { Fragment } from 'react'
const Button = (props) => {
  const icon = props.icon ? props.icon : false
  const iconRight = props.iconRight ? props.iconRight : false
  const loading = props?.loading || false
  const disabled = props?.disabled || false

  return (
    <Fragment>
      {!loading && (
        <button
          className={`btn ${disabled ? 'btn-dark btn-sm' : props.button}`}
          onClick={props.onClick}
          disabled={disabled}
        >
          {icon && <i className={props.icon}></i>}
          {props.title}
          {iconRight && <i className={props.iconRight}></i>}
          </button>
      )}

      {loading && (
        <button className={`btn ${props.button}`} disabled>
          <span
            className="spinner-border spinner-border-sm me-1"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      )}
    </Fragment>
  )
}

export default Button
