import { useState, useEffect } from 'react'
import Main from "../../../layouts/Main"
import Card from "../../../components/Card"
import InputGroup from "../../../components/InputGroup"
import Loader from '../../../components/Loader'
import Pagination from '../../../components/Pagination'
import RequestPointItem from './RequestPointItem'
import BonusPointService from '../../../services/BonusPoint'
import Detail from '../Detail'

const RequestPoint = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [modal, setModal] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    setLoading(true)

    BonusPointService.request(pageNumber, limit, search)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
      })
      .catch((err) => {
        console.log(err)
      })

    setLoading(false)
  }, [pageNumber, limit, search])

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
  }

  const changeRowPerPage = (event) => {
    setPageNumber(1)
    setLimit(+event.target.value)
  };

  const searchHandler = (value) => {
    setPageNumber(1)
    setSearch(value == '' ? null : value)
  }

  const closeEditHandler = () => {
    setModal(false)
  }

  if (loading === true) {
    return <Loader />
  }

  return (
    <Main title="Pengajuan Pencairan Poin">
      {modal && (
        <Detail
          data={dataDetail}
          activeModal={modal}
          closeClick={closeEditHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row justify-content-end">
          <div className="col">
            <div className="card-title"></div>
          </div>
          <div className="col-5">
            <InputGroup
              button="btn btn-primary"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-center">NO</th>
                    <th className="text-center">MITRA</th>
                    <th className="text-center">NOMINAL PENCAIRAN</th>
                    <th className="text-center">WAKTU PENGAJUAN</th>
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                {data.map((d, index) => (
                  <RequestPointItem 
                    key={d.id}
                    no={ index + (pageNumber === 1 ? 1 : limit * pageNumber - limit + 1) }
                    worker_id={d.worker_id}
                    worker_name={d.name}
                    nominal={d.point}
                    date={d.request_date}
                    status={d.status}
                    approve_by={d.approved_by}
                    approve_at={d.approved_at}
                    setModal={setModal}
                    setDataDetail={setDataDetail}
                  />
                ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="text-nowrap m-0">Row Per Page</p>
            <select
              className={`form-select text-dark`}
              onChange={changeRowPerPage}
              value={limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div>
          <Pagination pageCount={totalPage} changePage={changePageHandler} forcePage={pageNumber - 1}
          />
        </div>
      </Card>
    </Main>
  )
}

export default RequestPoint
