import { useState, useEffect } from 'react'
import Button from '../../components/Button'
import BonusPointService from '../../services/BonusPoint';

const BonusPointItem = (props) => {
  const detailHandler = () => {
    const data = {
      worker_id: props.worker_id,
      worker_name: props.name
    }

    props.setDataDetail(data)
    props.setModal(true)
  }

  return (
    <tr>
      <td className="text-center">{props.no}</td>
      <td className="text-center">{props.worker_id}</td>
      <td className="text-center">{props.name}</td>
      <td className="text-center">
        <Button
          iconRight="fa fa-chevron-right ms-2 fs-10"
          title="Mutasi Poin"
          button="text-primary fw-bold p-0"
          onClick={detailHandler}
          setModal={props.setModal}
        />
      </td>
    </tr>
  )
}

export default BonusPointItem
