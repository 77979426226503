import moment from 'moment'
import Styles from './BonusPoint.module.css'

const DetailItem = (props) => {
  return (
    <tr>
      <td>{props.no}</td>
      <td>{moment(props.data.date).format('DD/MM/YYYY HH:mm:ss')}</td>
      <td>{props.data.type == 'Credit' ? 'Kredit' : props.data.type}</td>
      <td>
        {(props.data.ids?.material_transaction_id) ? (
          <p>Pembelian Material<br /><b>ID Pembelian: {props.data.ids.material_transaction_id}</b></p>
        ) : (props.data.ids?.project_id) ? (
          <p>Rating {props.data.detail.slice(-3, -2)}<br /><b>ID Proyek: {props.data.ids.project_id}</b></p>
        ) : (props.data.status == 'Pencairan' && props.data.detail.includes('uang')) ? (
          <p>Pencairan Poin</p>
        ) : (props.data.detail.includes('ditolak')) ? (
          <p>Pencairan Poin ditolak</p>
        ) : (props.data.status === 'Pengembalian' && props.data.detail.includes('Penukaran')) ? (
          <p>{props.data.detail} ditolak</p>
        ) : (
          <p>{props.data.detail}</p>
        )}
      </td>
      <td>
        {+props.data.nominal == 0 ? (
          <div className={Styles.poin_zero}>{+props.data.nominal}</div>
        ) : (
          <div className={props.data.nominal > 0 ? Styles.poin_credit : Styles.poin_debit}>
            <div><i class="fa fa-arrow-up"></i></div>
            {props.data.nominal.toLocaleString()}
          </div>
        )}
      </td>
      <td>{props.data.saldo_poin.toLocaleString()}</td>
    </tr>
  )
}

export default DetailItem
