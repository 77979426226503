import moment from "moment"
import Button from "../../../components/Button"

const RequestPointItem = (props) => {
  const detailHandler = () => {
    const data = {
      worker_id: props.worker_id,
      worker_name: props.worker_name
    }
    props.setDataDetail(data)
    props.setModal(true)
  }

  return (
    <tr>
      <td className="text-center">{props.no}</td>
      <td className="text-center">
        <p className="m-0">{props.worker_id} | {props.worker_name}</p>
        <Button
          iconRight="fa fa-chevron-right ms-2 fs-10"
          title="Mutasi Poin"
          button="text-primary fw-bold p-0"
          onClick={detailHandler}
        />
      </td>
      <td className="text-center">{props.nominal.toLocaleString()}</td>
      <td className="text-center">{moment(props.date).format('DD/MM/YYYY HH:mm:ss')}</td>
      <td className="text-center">
        {(props.status === 'Pending') ? (
          <p className="m-0 fw-semibold text-warning">{props.status}</p>
        ) : (
          <>
            <p className={`m-0 fw-semibold text-${props.status === 'Ditolak' ? 'danger' : 'success'}`}>{props.status}</p>
            <p className="m-0">{props.approve_by} | {moment(props.approve_at).format('DD/MM/YYYY HH:mm:ss')}</p>
          </>
        )}
      </td>
    </tr>
  )
}

export default RequestPointItem
