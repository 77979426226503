import { useEffect, useState } from 'react'
import Modal from '../../components/Modal'
import Pagination from '../../components/Pagination'
import Styles from './BonusPoint.module.css'
import BonusPointService from '../../services/BonusPoint'
import DetailItem from './DetailItem'

const Detail = (props) => {
  console.log('///', props)
  const activeModal = props.activeModal ? 'effect-scale show' : ''
  const activeStyle = activeModal ? { display: 'block', paddingright: '17px' } : ''

  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [limitPage, setLimitPage] = useState(5)
  const [saldoPoin, setSaldoPoin] = useState(0)
  const [data, setData] = useState(null)

  const changePageHandler = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  useEffect(() => {
    BonusPointService.detail(props.data.worker_id, currentPage, limitPage)
      .then((res) => {
        setSaldoPoin(res.data.data.balance)
        setData(res.data.data.list)
        setTotalPage(res.data.total_page)

        setTimeout(() => { setLoading(false) }, 1000);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [currentPage]);

  return (
    <Modal
      activeModal={activeModal}
      activeStyle={activeStyle}
      title={`Detail Mutasi - ${props.data.worker_name}`}
      closeClick={props.closeClick}
      type="Detail"
      modalType="modal-xl"
      modalFooter="false"
    >
      <div className={Styles.saldo_poin} style={{ backgroundImage: `url("${window.location.origin}/assets/images/wave.svg")` }}>
        <img
          src={`${window.location.origin}/assets/images/wallet.svg`}
          alt="icon wallet saldo poin"
        />
        <p>Saldo Poin: {saldoPoin.toLocaleString()}</p>
      </div>

      <div className="table-responsive mt-5">
        <table className="table text-nowrap text-md-nowrap text-center mb-0">
          <thead>
            <tr>
              <th>NO</th>
              <th>TANGGAL</th>
              <th>TIPE TRANSAKSI</th>
              <th>DETAIL TRANSAKSI</th>
              <th>NOMINAL</th>
              <th>SALDO POIN</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan="6">Loading....</td>
              </tr>
            )}
            {!loading && data?.map((d, index) => (
              <DetailItem 
                no={ index + (currentPage === 1 ? 1 : limitPage * currentPage - limitPage + 1) }
                data={d}
              />
            ))}
            
            {!loading && data?.length < 1 && (
              <tr>
                <td colSpan="6">Tidak ada data</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col">
          <Pagination pageCount={totalPage} changePage={changePageHandler} forcePage={currentPage - 1} />
        </div>
      </div>
    </Modal>
  )
}

export default Detail
