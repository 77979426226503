import { useState, useEffect } from 'react'
import Main from '../../layouts/Main'
import Card from '../../components/Card'
import InputGroup from '../../components/InputGroup'
import Pagination from '../../components/Pagination'
import BonusPointItem from './BonusPointItem'
import BonusPointService from '../../services/BonusPoint'
import Loader from '../../components/Loader'
import Detail from './Detail'

const BonusPoint = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [modal, setModal] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)
  const [search, setSearch] = useState(null)

  // Fetch Data
  useEffect(() => {
    setLoading(true)

    BonusPointService.workers(pageNumber, limit, search)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
      })
      .catch((err) => {
        console.log(err)
      })

    setLoading(false)
  }, [pageNumber, limit, search])

  const changePageHandler = ({ selected }) => {
    setPageNumber(selected + 1)
  }

  const changeRowPerPage = (event) => {
    setPageNumber(1)
    setLimit(+event.target.value)
  };

  const searchHandler = (value) => {
    setSearch(value)
    setPageNumber(1)
  }

  const closeEditHandler = () => {
    setModal(false)
  }

  if (loading === true) {
    return <Loader />
  }

  return (
    <Main title="Mutasi Poin Mitra">
      {modal && (
        <Detail
          data={dataDetail}
          activeModal={modal}
          closeClick={closeEditHandler}
        />
      )}

      <Card col={'col-xl-12 col-lg-12'}>
        <div className="row justify-content-end">
          <div className="col">
            <div className="card-title"></div>
          </div>
          <div className="col-5">
            <InputGroup
              button="btn btn-primary"
              icon="fa fa-search"
              searchHandler={searchHandler}
            />
          </div>
        </div>

        <div className="row mt-5 mb-5">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table text-nowrap text-md-nowrap mb-0">
                <thead>
                  <tr>
                    <th className="text-center">NO</th>
                    <th className="text-center">ID MITRA</th>
                    <th className="text-center">MITRA</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((d, index) => (
                    <BonusPointItem
                      key={d.user_id}
                      no={
                        index +
                        (pageNumber === 1 ? 1 : limit * pageNumber - limit + 1)
                      }
                      worker_id={d.user_id}
                      name={d.name}
                      setModal={setModal}
                      setDataDetail={setDataDetail}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-2">
            <p className="text-nowrap m-0">Row Per Page</p>
            <select
              className="text-dark form-select me-3"
              onChange={changeRowPerPage}
              value={limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
            </select>
          </div>
            <Pagination pageCount={totalPage} changePage={changePageHandler} forcePage={pageNumber - 1} />
        </div>

      </Card>
    </Main>
  )
}

export default BonusPoint
